<template>
  <!-- 相关投诉的左侧 -->
  <div>
    <div class="crumbs">
      <a href="/">首页</a> > <a :href="'/Details?id='+id.id">投诉详情</a> >
      相关投诉
    </div>
    <div class="Business_Homepage">
      <div class="Business_Homepage_left">
        <img v-if="count.image == ''"
          src="https://dss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2165691866,2570924737&fm=26&gp=0.jpg"
          alt="" />
        <img v-if="count.image!=''" :src="count.image" alt="">
        <div class="dw_title" v-if="count.is_claim == 1">商</div>
      </div>
      <div class="Business_Homepage_right">
        <div class="Business_Homepage_right_top">
          <div class="Business_Homepage_right_top_left">{{count.name}}</div>
          <div class="Business_Homepage_right_top_right" v-if="count.is_claim == 0" @click="Go_claim">
            <img src="../assets/img/renling_icon.png" alt="" />
            去认领
          </div>
        </div>
        <div class="Business_Homepage_right_centent">
          <div class="Business_Homepage_right_centent_pingxing">
            <div>效率</div>
            <div v-for="(item,index) in new_efficiency_results_level" :key="index">
              <img :src="item" alt="" />
            </div>
          </div>
          <div class="Business_Homepage_right_centent_pingxing">
            <div>满意度</div>
            <div v-for="(item,index) in new_service_level" :key="index">
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
        <div class="Business_Homepage_right__bottom">
          <div class="Business_Homepage_right__bottom_num">
            <div class="num">{{count.count}}</div>
            <div class="word">总投诉</div>
          </div>
          <div class="Business_Homepage_right__bottom_num">
            <div class="num">{{count.completed}}</div>
            <div class="word">已完成</div>
          </div>
          <div class="Business_Homepage_right__bottom_num">
            <div class="num">{{count.hang}}</div>
            <div class="word">未完成</div>
          </div>
        </div>
      </div>
    </div>

    <van-tabs color="#1f72d9">
      <van-tab title="总投诉">
        <div class="Business_Homepage_list" v-for="(item,index) in List" :key="index" @click="G_Details(item.id)">
          <div class="Business_Homepage_list_top">
            <div class="Business_Homepage_list_top_left">{{item.statement_title}}</div>
            <div class="Business_Homepage_list_top_right"><span>已完成</span></div>
          </div>
          <div class="Business_Homepage_list_content">
            <div class="Business_Homepage_list_content_w"><span class="Business_Homepage_list_content_bg">[投诉对象]</span>
              {{count.name}}
            </div>
            <div class="Business_Homepage_list_content_w"><span class="Business_Homepage_list_content_bg">[投诉理由]</span>
              {{item.complaint_reason}}
            </div>
          </div>
          <div class="Business_Homepage_list_bottom">
            <div class="Business_Homepage_list_bottom_left">
              <img :src="item.headimgurl" alt="">
              <div class="names">{{item.nickname}}</div>
              <div class="datas">{{item.create_time}}</div>
            </div>
            <div class="Business_Homepage_list_bottom_right">
              <div class="Business_Homepage_list_bottom_right_b">
                <img src="../assets/img/dianzan_icon.png" alt="">
                <div>{{item.like_num}}</div>
              </div>
              <div class="Business_Homepage_list_bottom_right_b">
                <img src="../assets/img/pinglun_icon.png" alt="">
                <div>{{item.comment_num}}</div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="已完成">
        <div class="Business_Homepage_list" v-for="(item,index) in List1" :key="index" @click="G_Details(item.id)">
          <div class="Business_Homepage_list_top">
            <div class="Business_Homepage_list_top_left">{{item.statement_title}}</div>
            <div class="Business_Homepage_list_top_right"><span>已完成</span></div>
          </div>
          <div class="Business_Homepage_list_content">
            <div class="Business_Homepage_list_content_w"><span class="Business_Homepage_list_content_bg">[投诉对象]</span>
              {{count.name}}
            </div>
            <div class="Business_Homepage_list_content_w"><span class="Business_Homepage_list_content_bg">[投诉理由]</span>
              {{item.complaint_reason}}
            </div>
          </div>
          <div class="Business_Homepage_list_bottom">
            <div class="Business_Homepage_list_bottom_left">
              <img :src="item.headimgurl" alt="">
              <div class="names">{{item.nickname}}</div>
              <div class="datas">{{item.create_time}}</div>
            </div>
            <div class="Business_Homepage_list_bottom_right">
              <div class="Business_Homepage_list_bottom_right_b">
                <img src="../assets/img/dianzan_icon.png" alt="">
                <div>{{item.like_num}}</div>
              </div>
              <div class="Business_Homepage_list_bottom_right_b">
                <img src="../assets/img/pinglun_icon.png" alt="">
                <div>{{item.comment_num}}</div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="未完成">
        <div class="Business_Homepage_list" v-for="(item,index) in List2" :key="index" @click="G_Details(item.id)">
          <div class="Business_Homepage_list_top">
            <div class="Business_Homepage_list_top_left">{{item.statement_title}}</div>
            <div class="Business_Homepage_list_top_right"><span>已完成</span></div>
          </div>
          <div class="Business_Homepage_list_content">
            <div class="Business_Homepage_list_content_w"><span class="Business_Homepage_list_content_bg">[投诉对象]</span>
              {{count.name}}
            </div>
            <div class="Business_Homepage_list_content_w"><span class="Business_Homepage_list_content_bg">[投诉理由]</span>
              {{item.complaint_reason}}
            </div>
          </div>
          <div class="Business_Homepage_list_bottom">
            <div class="Business_Homepage_list_bottom_left">
              <img :src="item.headimgurl" alt="">
              <div class="names">{{item.nickname}}</div>
              <div class="datas">{{item.create_time}}</div>
            </div>
            <div class="Business_Homepage_list_bottom_right">
              <div class="Business_Homepage_list_bottom_right_b">
                <img src="../assets/img/dianzan_icon.png" alt="">
                <div>{{item.like_num}}</div>
              </div>
              <div class="Business_Homepage_list_bottom_right_b">
                <img src="../assets/img/pinglun_icon.png" alt="">
                <div>{{item.comment_num}}</div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Business_complaints } from "../api/api.js";
export default {
   metaInfo(){
     return{
        title: '品牌主页',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  name: "RelatedLeft",
  data() {
    return {
      id: {},
      count: {}, //商家展示
      new_efficiency_results_level: [], //效率评星
      new_service_level: [], //满意度评星
      List: [], //总投诉展示列表
      List1: [], //已完成展示列表
      List2: [], //未完成展示列表
      G_count:'',   //去认领带的品牌id和name
    };
  },
  created() {
    var that = this;
    let routerParams = this.$route.query;
    console.log(this.$route.query)
    this.id = routerParams;
    //console.log(routerParams);
    that.Business_complaints();
    that.Business_complaints1();
    that.Business_complaints2();
  },
  methods: {
    Business_complaints() {
      let that = this;
      let new_efficiency_results_level = [];
      let new_service_level = [];
      Business_complaints({ business_id: that.id.Obid, type: 0 }).then(
        (res) => {
          //console.log(res.data.count);
          that.G_count=JSON.stringify(res.data.count)
          that.count = res.data.count;
          switch (res.data.count.efficiency_results_level) {
            case 1:
              new_efficiency_results_level = [
                require("../assets/img/xingxing.png"),
              ];
              break;
            case 2:
              new_efficiency_results_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
            case 3:
              new_efficiency_results_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
            case 4:
              new_efficiency_results_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
            case 5:
              new_efficiency_results_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
          }
          switch (res.data.count.service_level) {
            case 1:
              new_service_level = [require("../assets/img/xingxing.png")];
              break;
            case 2:
              new_service_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
            case 3:
              new_service_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
            case 4:
              new_service_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
            case 5:
              new_service_level = [
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
                require("../assets/img/xingxing.png"),
              ];
              break;
          }
          that.new_efficiency_results_level = new_efficiency_results_level;
          that.new_service_level = new_service_level;
          that.List = res.data.data.data;
          //console.log(res.data.data);
        }
      );
    },
    Business_complaints1() {
      let that = this;
      Business_complaints({ business_id: that.id.Obid, type: 1 }).then(
        (res) => {
          console.log(res.data.count);
          that.count = res.data.count;
          that.List1 = res.data.data.data;
        }
      );
    },
    Business_complaints2() {
      let that = this;
      Business_complaints({ business_id: that.id.Obid, type: 2 }).then(
        (res) => {
          //console.log(res.data.count);
          that.count = res.data.count;
          that.List2 = res.data.data.data;
        }
      );
    },

    // 跳转详情
    G_Details(id) {
      var that = this;
      //console.log(id);
      this.$router.push({ name: "Details", query: { id: id } });
    },
    Go_claim(){
       this.$router.push({ name: "Entry_Enterprises", query: { G_count: this.G_count } });
    }
  },
};
</script>

<style scoped>
.Business_Homepage {
  width: 860px;
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  display: flex;
}
.Business_Homepage_right {
  margin-left: 30px;
}
.Business_Homepage_left{
  position:relative;
}
.dw_title{
  position:absolute;
  bottom:21px;
  right:0;
  width:40px;
  height:40px;
background: #1F72D9;
border-radius: 50%;
text-align:center;
line-height:40px;
font-size: 20px;  
color: #FFFFFF;
}

.Business_Homepage_left img {
  margin-top: 25px;
  border-radius: 50%;
  object-fit: cover;
  width: 145px;
  height: 145px;
}
.Business_Homepage_right_top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.Business_Homepage_right_top_right {
  display: flex;
  margin-left: 20px;
  width: 96px;
  height: 30px;
  background: #fe9025;
  text-align: center;
  border-radius: 20px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
}
.Business_Homepage_right_top_left {
  font-size: 29px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.Business_Homepage_right_top_right img {
  width: 18px;
  height: 18px;
  margin-top: 5px;
  margin-left: 15px;
}
.Business_Homepage_right_centent_pingxing {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.Business_Homepage_right_centent_pingxing:nth-child(1) {
  margin-left: 16px;
  margin-bottom: 10px;
}
.Business_Homepage_right_centent_pingxing img {
  width: 22px;
  height: 22px;
}
.Business_Homepage_right_centent_pingxing div:nth-child(1) {
  font-size: 16px;
  margin-right: 10px;
  color: #666666;
}
.Business_Homepage_right__bottom {
  margin-top: 20px;
  display: flex;
  text-align: center;
}
.Business_Homepage_right__bottom_num:nth-child(1) {
  font-size: 28px;
  color: #1f72d9;
  padding-right: 15px;
  border-right: 1px solid #ccc;
}
.Business_Homepage_right__bottom_num:nth-child(2) {
  font-size: 28px;
  color: #1f72d9;
  padding: 0 15px;
  border-right: 1px solid #ccc;
}
.Business_Homepage_right__bottom_num:nth-child(3) {
  font-size: 28px;
  color: #1f72d9;
  padding: 0 15px;
}
.Business_Homepage_right__bottom_num .word {
  font-size: 18px;
  color: #666666;
}
.Business_Homepage_list {
  border-bottom: 1px solid #ccc;
  background: #fff;
  cursor: pointer;
}
>>> .van-tabs__line {
  height: 1px !important;
  margin-top: 20px;
}
>>> .van-tab {
  flex: none;
  margin-right: 15px;
  margin-left: 15px;
}
>>> .van-tabs__wrap {
  border-bottom: 1px solid #ccc;
}
>>> .van-tab__text {
  font-size: 17px;
}
.Business_Homepage_list_top {
  padding: 17px 17px 5px 17px;
  display: flex;
  justify-content: space-between;
}
.Business_Homepage_list_top_left {
  font-size: 22px;
  width: 700px;
}
.Business_Homepage_list_top_right {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #d2e3f7;
  border-radius: 4px;
  text-align: center;
}
.Business_Homepage_list_top_right span {
  color: #1f72d9;
}
.Business_Homepage_list_content {
  padding: 5px 17px 10px 17px;
}
.Business_Homepage_list_content_bg {
  color: #1f72d9;
}
.Business_Homepage_list_content_w {
  width: 700px;
  margin-top: 5px;
}
.Business_Homepage_list_bottom {
  padding: 10px 17px 20px 17px;
  display: flex;
  justify-content: space-between;
}
.Business_Homepage_list_bottom_left {
  display: flex;
  align-items: center;
}
.Business_Homepage_list_bottom_left img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.Business_Homepage_list_bottom_left .names {
  margin-left: 5px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 18px;
}
.Business_Homepage_list_bottom_left .datas {
  margin-left: 30px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.Business_Homepage_list_bottom_right_b {
  display: flex;
  align-items: center;
}
.Business_Homepage_list_bottom_right {
  display: flex;
}
.Business_Homepage_list_bottom_right_b img {
  width: 20px;
  height: 20px;
  margin-left: 30px;
  margin-right: 5px;
}
.Business_Homepage_list_bottom_right_b div {
  color: #999999;
}
</style>